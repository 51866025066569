import React, { Component } from "react";
import Rulers from "./Components/Rulers";
// import PdfDemo from "./pdfDemo";
import "./App.css";

// <br />
// <PdfDemo />

class App extends Component {
  render() {
    return (
      <div className="App">
        <Rulers />
      </div>
    );
  }
}

export default App;
